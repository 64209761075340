import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
import queryString from 'query-string'
const baseUrl = getApi+"straincollections";

export const straincollectionService = {
    getAll,
};

function getAll(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}`}
    else{fetchParam=`${baseUrl}${query}`}
    return fetchWrapper.get(fetchParam);
}

