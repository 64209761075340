import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
import queryString from 'query-string'
const baseUrl = getApi+"cities";

export const cityService = {
    getAll,
    findBySlug,
    findByNameAndState
};

function getAll(query='') {
    return fetchWrapper.get(`${baseUrl}${query}`);
}

// function findByUUID(UUID) {
//     return fetchWrapper.get(`${baseUrl}/${UUID}`);
// }

function findBySlug(slug, abbr) {
    return fetchWrapper.get(`${baseUrl}/bySlug/${slug}/${abbr}`);
}

function findByNameAndState(name, stateId) {
    return fetchWrapper.get(`${baseUrl}/byName/${name}/${stateId}`);
}