import {fetchWrapper} from '../helpers/fetch-wrapper';
import { getApi } from '../config';
const baseUrl = getApi+"notifications";

export const notificationService = {
    get,
    update
};

function get(params='') {
    return fetchWrapper.get(`${baseUrl}${params}`);
}

function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params)
}


