import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
import queryString from 'query-string'
const baseUrl = getApi+"categories";

export const categoryService = {
    getBySlug,
    getFilters,
    getPaginated
};

function getBySlug(slug) {
    return fetchWrapper.get(`${baseUrl}/${slug}`);
}

function getPaginated(query) {
    return fetchWrapper.get(`${baseUrl}${query}`);
}

function getFilters(slug) {
    return fetchWrapper.get(`${baseUrl}/filters/${slug}`);
}