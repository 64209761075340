import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
import queryString from 'query-string'
const baseUrl = getApi+"favorites";

export const favoriteService = {
    getAllByCollection,
    getAll,
    manage
};

function getAll() {
    return fetchWrapper.get(`${baseUrl}`);
}

function getAllByCollection(collection, query, token) {
    return fetchWrapper.get(`${baseUrl}/${collection}${query}`, token);
}

function manage(body) {
    return fetchWrapper.post(baseUrl, body);
}

