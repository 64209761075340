import {fetchWrapper} from '../helpers/fetch-wrapper';
import { getApi } from '../config';
const baseUrl = getApi+"users";

export const userService = {
    findByEmail,
    getPaginated
};

function findByEmail(email, userToken) {
    return fetchWrapper.get(`${baseUrl}/findByEmail/${email}`, userToken);
}

function getPaginated(query) {
    return fetchWrapper.get(`${baseUrl}${query}`);
}