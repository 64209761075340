import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
const baseUrl = getApi+"states";

export const stateService = {
    findByUUID,
    findByShortname,
    findById,
    findByAbr,
    getAll,
};

function findByShortname(shortname){
    return fetchWrapper.get(`${baseUrl}/byShort/${shortname}`);
}

function findByUUID(UUID) {
    return fetchWrapper.get(`${baseUrl}/${UUID}`);
}

function findByAbr(abr){
    return fetchWrapper.get(`${baseUrl}/byAbr/${abr}`)
}

function findById(id){
    return fetchWrapper.get(`${baseUrl}/byId/${id}`);
}

function getAll(query=''){
    return fetchWrapper.get(`${baseUrl}${query}`);
}


