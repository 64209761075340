import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
import queryString from 'query-string'
const baseUrl = getApi+"strains";

export const strainService = {
    getAll,
    getBySlug,
    buildAll,
    getPaginated,
    update,
    getFeaturedBrands
};

function getPaginated(query) {
    return fetchWrapper.get(`${baseUrl}${query}`);
}

function getFeaturedBrands(id) {
    return fetchWrapper.get(`${baseUrl}/brands/${id}`);
}

function getAll(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}`}
    else{fetchParam=`${baseUrl}${query}`}
    return fetchWrapper.get(fetchParam);
}

function buildAll() {
    return fetchWrapper.get(`${baseUrl}/build`);
}


function getBySlug(slug) {
    return fetchWrapper.get(`${baseUrl}/${slug}`);
}

function update(type, slug, params){
    return fetchWrapper.put(`${baseUrl}/${type}/${slug}`, params);
}

