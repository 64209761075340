import {fetchWrapper} from '../helpers/fetch-wrapper';
import { getApi } from '../config';
const baseUrl = getApi+"cartitems";

export const cartItemService = {
    get,
    getById,
    create,
    update,
    getByUserId,
    getProductsByBrandId,
    delete: _delete,
};


function get() {
    return fetchWrapper.get(`${baseUrl}/`);
}

function getById(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`);
}

function getProductsByBrandId(id) {return fetchWrapper.get(`${baseUrl}/${id}/products/`);}

function getByUserId(id) {
   
    return fetchWrapper.get(`${baseUrl}/user/${id}`).then(category => {
        return category;
})}

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params)
}

function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`)
}
