import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
const baseUrl = getApi+"sitemap";

export const sitemapService = {
    get,
    count,
    lastUpdated
};

function get(queries = ''){
    return fetchWrapper.get(`${baseUrl}${queries}`);
}

function count(){
    return fetchWrapper.get(`${baseUrl}/count`);
}

function lastUpdated(){
    return fetchWrapper.get(`${baseUrl}/lastUpdated`)
}

