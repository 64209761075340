import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
const baseUrl = getApi+"searches";

export const searchService = {
    searchAll,
};

function searchAll(query) {
    return fetchWrapper.get(`${baseUrl}${query}`);
}