import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
import queryString from 'query-string'
const baseUrl = getApi+"deals";

export const dealService = {
    getAll,
    getById,
    getBySlug,
    getNear,
    getByDispensary,
    getByCount,
    findByDispensaryIdAndSlug,
    getInState
};

function getByDispensary(dispensary, query='') {
    return fetchWrapper.get(`${baseUrl}/business/${dispensary}${query}`);
}

function getAll(query) {

    return fetchWrapper.get(`${baseUrl}${query}`);
}

function getNear(query) {
   
    return fetchWrapper.get(`${baseUrl}/near${query}`);
}

function getInState(state, query) {
    
    return fetchWrapper.get(`${baseUrl}/in/${state}${query}`)
}

function getById(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`);
}

function getBySlug(dispensarySlug, slug) {
    return fetchWrapper.get(`${baseUrl}/${dispensarySlug}/${slug}`);
}

function getByCount(dispensarySlug, count) {
    return fetchWrapper.get(`${baseUrl}/${dispensarySlug}/count/${count}`);
}

function findByDispensaryIdAndSlug(dispensaryId, slug) {
    return fetchWrapper.get(`${baseUrl}/dispensary_id/${dispensaryId}/${slug}`);
}