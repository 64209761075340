import {fetchWrapper} from '../helpers/fetch-wrapper';
import { getApi } from '../config';
const baseUrl = getApi+"pointstransactions";

export const pointstransactionService = {
    get,
    create,
    findOne
};

function get(params='', userToken) {
    return fetchWrapper.get(`${baseUrl}${params}`, userToken);
}

function findOne(dispensary) {
    return fetchWrapper.get(`${baseUrl}/${dispensary}`);
}

function create(params) {
    return fetchWrapper.post(`${baseUrl}`, params);
}