import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
import queryString from 'query-string'
const baseUrl = getApi+"brands";

export const brandService = {
    getAll,
    getById,
    buildAll,
    getPaginated,
    getFilters,
    getBySlug,
    findRetailers
};

function getPaginated(query) {
    return fetchWrapper.get(`${baseUrl}${query}`);
}

function getAll(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}`}
    else{fetchParam=`${baseUrl}${query}`}
    return fetchWrapper.get(fetchParam);
}

function findRetailers(slug, query) {
    return fetchWrapper.get(`${baseUrl}/retailers/${slug}${query}`)
}

function buildAll() {
    return fetchWrapper.get(`${baseUrl}/build`);
}


function getById(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`);
}

function getFilters(id) {
    return fetchWrapper.get(`${baseUrl}/filters/${id}`);
}

function getBySlug(slug) {
    return fetchWrapper.get(`${baseUrl}/${slug}`);
}
