import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
import queryString from 'query-string'
const baseUrl = getApi+"products";

export const productService = {
    getAll,
    getById,
    getRetailers,
    buildAll,
    getBySlugAndBrand,
    getPaginated,
    getRandomByStrain,
    getMainPage,
    getCategoryProductsFromParent,
    getFilter,
    getLocationFilter,
    getVariantFilter
};

function getCategoryProductsFromParent (slug, query) {
    return fetchWrapper.get(`${baseUrl}/category_products_from_parent/${slug}/${query}`)
}

function getPaginated(query) {
    return fetchWrapper.get(`${baseUrl}${query}`);
}

function getFilter(id, query, collection) {
    return fetchWrapper.get(`${baseUrl}/business/${id}/filters/${collection}${query}`);
}

function getLocationFilter(collection, query = '') {
    return fetchWrapper.get(`${baseUrl}/filters/${collection}${query}`);
}

function getMainPage(query) {
    return fetchWrapper.get(`${baseUrl}/main_page${query}`);
}

function getRandomByStrain(id) {
    return fetchWrapper.get(`${baseUrl}/random_strain/${id}`)
}


function getAll(dispensaryId, query='') {
    return fetchWrapper.get(`${baseUrl}/${dispensaryId}${query}`);
}

function buildAll() {
    return fetchWrapper.get(`${baseUrl}/build`);
}


function getById(id) {
    return fetchWrapper.get(`${baseUrl}/by_id/${id}`);
}


function getRetailers(id, query) {
    return fetchWrapper.get(`${baseUrl}/retailers/${id}?${query}`);
}

function getBySlugAndBrand(queryString) {
    return fetchWrapper.get(`${baseUrl+'/brand_and_slug?'+queryString}`);
}

function getVariantFilter(entitySlug) {
    return fetchWrapper.get(`${baseUrl+'/getVariantFilter/'+entitySlug}`);
}