import {fetchWrapper} from '../helpers/fetch-wrapper';
import { getApi } from '../config';
const baseUrl = getApi+"receipts";

export const receiptService = {
    get,
    create,
};

function get(params='', userToken) {
    return fetchWrapper.get(`${baseUrl}${params}`, userToken);
}

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}