import {fetchWrapper} from '../helpers/fetch-wrapper';
import { getApi } from '../config';
const baseUrl = getApi+"terms";

export const termService = {
    getAll,
};

function getAll(query='') {
    return fetchWrapper.get(`${baseUrl}${query}`);
}