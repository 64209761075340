const getApi = process.env.NEXT_PUBLIC_API_URL;
const getMapboxAccessToken = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN

const productPageSections = {
    sliderData: [
        { name:'Cartridge', type:'category'},
        { name:'Edibles', type:'parentcategory'},
        { name:'Flower', type:'category'},
        { name:'Vape Pens', type:'parentcategory'},
        { name:'Topicals', type:'category'},
        { name:'Pre Roll', type:'category'},
    ]
}

const productPageSectionsLocal = {
    sliderData: [
        { name:'Cartridge', parentcategory:'vape-pens'},
        { name:'Edibles', parentcategory:false },
        { name:'Flower', parentcategory:'flower'},
        { name:'Vape Pens', parentcategory:false},
        { name:'Topicals', parentcategory:'topicals'},
        { name:'Pre Roll', parentcategory:'flower'},
    ]
}



export { getApi, getMapboxAccessToken, productPageSections, productPageSectionsLocal };