import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
import queryString from 'query-string'
const baseUrl = getApi+"menuproducts";

export const menuProductService = {
    getPaginated,
    getBySlug,
    getAllPaginated
};


function getPaginated(query) {
    return fetchWrapper.get(`${baseUrl}/list${query}`);
}

function getBySlug(slug){
    return fetchWrapper.get(`${baseUrl}/${slug}`);
}

function getAllPaginated(query) {
    return fetchWrapper.get(`${baseUrl}/publiclist${query}`);
}